import { SETTINGS } from "../endpoints";
import request from "../request";
import { ServerSettings } from "../../types/Settings";

/**
 * Obtiene la fecha y hora del server con su offset segun la config de la univ
 */
export const getServerTimezone = () => {
  const url = SETTINGS.TIMEZONE;
  return request<ServerSettings>(url);
};
