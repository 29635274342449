import { useUserState } from "../../../../hooks/useUserState";
import StudentFileNoSua from "./StudentFileNoSua";
import WaitingList from "../WaitingList";

const StudentFileNoSuaStep = () => {
  const { statusPostulation } = useUserState();

  if (statusPostulation === "IN_WAITING_LIST") {
    return <WaitingList />;
  }
  return <StudentFileNoSua />;
};

export default StudentFileNoSuaStep;
