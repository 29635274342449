import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModalDocumentType } from "../../../../../types/signDocumentationTypes";

export default function useDocsContent() {
  const prefix = "postulationDocs";

  const { t } = useTranslation();

  return useMemo<ModalDocumentType>(
    () => ({
      contract: {
        name: t(`${prefix}.contract.name`),
        hasTooltip: true,
        icon: "contract",
      },
      lem: {
        name: t(`${prefix}.lem.name`),
        hasTooltip: true,
        icon: "certificate",
      },
      nem: {
        name: t(`${prefix}.nem.name`),
        hasTooltip: true,
        icon: "certificate",
      },
      "postulant-passport": {
        name: t(`${prefix}.postulant-passport.name`),
        icon: "id",
      },
      "sustainer-passport": {
        name: t(`${prefix}.sustainer-passport.name`),
        icon: "id",
      },
      "cosigner-passport": {
        name: t(`${prefix}.cosigner-passport.name`),
        icon: "id",
      },
      student: {
        name: t(`${prefix}.postulant-passport.name`),
        icon: "id",
      },
      sustainer: {
        name: t(`${prefix}.sustainer-passport.name`),
        icon: "id",
      },
      cosigner: {
        name: t(`${prefix}.cosigner-passport.name`),
        icon: "id",
      },
      "sustainer-income-one": {
        name: t(`${prefix}.sustainer-income-one.name`),
        hasTooltip: true,
        icon: "promissory_note",
      },
      "sustainer-income-others": {
        name: t(`${prefix}.sustainer-income-others.name`),
        hasTooltip: true,
        icon: "promissory_note",
      },
      "promissory-note": {
        name: "promissoryNote",
        icon: "promissory_note",
      },
      insurance: {
        name: "insurance",
        icon: "sustaining_contract",
      },
    }),
    [t]
  );
}
