import { Steps } from "@octano/global-ui";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import { getStatusTuitionProcess } from "../../api/requests/tuitionProcess";
import DisplayError from "../../components/info/DisplayError";
import { useLoadingState } from "../../hooks/useLoadingState";
import { useStepState } from "../../hooks/useStepState";
import { useTuitionProcessNoSuaSteps } from "../../hooks/useTuitionProcessNoSuaSteps";
import { useUserState } from "../../hooks/useUserState";
import StepProvider from "../../providers/StepProvider";
import withProvider from "../../providers/withProvider";

/**
 * Vista del proceso de matrícula del proceso SIN SUA
 */
const TuitionProcessNoSua = () => {
  const [idStep, setIdStep] = useState<string>();
  const {
    setUserFullName,
    setUserRut,
    setIsSessionExpired,
    setStatusPostulation,
  } = useUserState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { namesSteps, CurrentStepComponent, getIndexStep } =
    useTuitionProcessNoSuaSteps();
  const { currentStep, setCurrentStep } = useStepState();

  const getStatus = useCallback(async () => {
    const { data, error } = await getStatusTuitionProcess();
    if (data) {
      setUserFullName(data.studentFullName);
      setUserRut(data.studentRut);
      setIdStep(data.step);
      setErrorLoading(undefined);
      setStatusPostulation(data.status || "");
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    setUserFullName,
    setUserRut,
    setStatusPostulation,
    setIsSessionExpired,
  ]);

  useEffect(() => {
    if (loading) {
      getStatus();
    }
  }, [loading, getStatus]);

  useEffect(() => {
    if (idStep) {
      const index = getIndexStep(idStep);
      setCurrentStep(index);
    }
  }, [idStep, getIndexStep, setCurrentStep]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return null;
  }
  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {/* Se renderiza el componente adecuado para cada paso */}
        {CurrentStepComponent && <CurrentStepComponent />}
      </Col>
    </Row>
  );
};

export default withProvider(TuitionProcessNoSua, StepProvider);
