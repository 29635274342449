import { Dispatch } from "react";

export enum UserActionTypes {
  RESET_STATE = "RESET_STATE",
  SET_IS_LOGGED = "SET_IS_LOGGED",
  SET_IS_SESSION_EXPIRED = "SET_IS_SESSION_EXPIRED",
  SET_FULL_NAME = "SET_FULL_NAME",
  SET_USER_RUT = "SET_USER_RUT",
  SET_STATUS_POSTULATION = "SET_STATUS_POSTULATION",
}

export type UserAction =
  | { type: UserActionTypes.RESET_STATE }
  | { type: UserActionTypes.SET_IS_LOGGED; payload: boolean }
  | { type: UserActionTypes.SET_IS_SESSION_EXPIRED; payload: boolean }
  | { type: UserActionTypes.SET_FULL_NAME; payload: string }
  | { type: UserActionTypes.SET_USER_RUT; payload: string }
  | { type: UserActionTypes.SET_STATUS_POSTULATION; payload: string };

export type UserState = {
  isLogged: boolean;
  isSessionExpired: boolean;
  fullName: string;
  rut: string;
  statusPostulation: string;
};

export type UserContextType = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};
