import { Box, Button, TextInput } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { CertificateType } from "../../types/certificateTypes";
import { formatDate } from "../../utils/dates";
import { UrlLink } from "../TuitionProcessNoSua/parts/Documentation/DocumentCard";

const CertificateDetails = () => {
  const prefix = "CertificateValidation.details";
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<CertificateType>();

  return (
    <Card className="p-4 mt-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <Button
            icon="back"
            onClick={() => history.goBack()}
            outlined
            rounded
            size="sm"
            text={t(`${prefix}.goBack`)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={{ size: 8, offset: 2 }}>
          <Row>
            <Col xs={12}>
              <p className="fs-25 fw-700 text-center text-primary mt-4 mb-5">
                {t(`${prefix}.title`)}
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <TextInput
                name="duration"
                label={t(`${prefix}.duration`)}
                disabled
                value={formatDate(state.duration)}
              />
            </Col>
            <Col xs={12} md={6}>
              <Box
                variant="outlined"
                color="secondary"
                title={t(`${prefix}.status`)}
                body={state.status}
                fullwidth
                style={{ padding: "10px 15px" }}
                className={
                  state.status === "inactivo"
                    ? "border border-danger text-danger"
                    : ""
                }
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <TextInput
                name="name"
                label={t(`${prefix}.name`)}
                disabled
                value={state.studentName}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextInput
                name="rut"
                label={t(`${prefix}.rut`)}
                disabled
                value={state.rut}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <TextInput
                name="certificate"
                label={t(`${prefix}.certificate`)}
                disabled
                value={state.certificate}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextInput
                name="invoice"
                label={t(`${prefix}.invoice`)}
                disabled
                value={state.invoice}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={{ size: 4, offset: 4 }} className="mb-4">
              <UrlLink href={state.documentUrl}>
                <Button
                  icon="search"
                  outlined
                  size="md"
                  text={t(`${prefix}.look`)}
                  fullwidth
                />
              </UrlLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default CertificateDetails;
