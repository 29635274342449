import dayjs from "dayjs";

interface FormatDate {
  datetime: dayjs.Dayjs;
  day?: number;
  month?: number;
}

/**
 * Toma un objeto con una propiedad de fecha y hora y dos propiedades opcionales, día y mes, y devuelve
 * una cadena
 * @param {FormatDate}  - datetime - la fecha a ser formateada
 * @returns Cadena con fecha calculada y formateada
 */
function generateDateString({
  datetime,
  day = 0,
  month = 0,
}: FormatDate): string {
  const format = "DD/MM/YYYY";
  return datetime.add(day, "day").add(month, "month").format(format);
}

/**
 * Toma un mes, un día y una fecha, y devuelve una cadena con la fecha de inicio y finalización del
 * período de pago.
 * Los casos de fechas posible son:
 *  5: fecha de pago los primeros 5 dias del mes
 *  -5 fecha de pago los ultimos 5 dias del mes
 *  15 fecha de pago los dias que van del 15-2 al 15+2 (13 al 17)
 * @param {number} month - número,
 * @param {number} day - número,
 * @param currentDate - dayjs.dayjs
 * @returns Una cadena con el intervalo de fechas
 */
export function generateDateRangeString(
  month: number,
  day: number,
  currentDate: dayjs.Dayjs
): string | undefined {
  //Meses van del 0 al 11
  if (day === 5) {
    const payDay = currentDate.startOf("month");
    const startDate = generateDateString({ datetime: payDay, month });
    const endDate = generateDateString({ datetime: payDay, day, month });
    return `${startDate} al ${endDate}`;
  }
  if (day === -5) {
    const payDay = currentDate.endOf("month");
    const startDate = generateDateString({ datetime: payDay, month, day });
    const endDate = generateDateString({ datetime: payDay, month });
    return `${startDate} al ${endDate}`;
  }

  if (day === 15) {
    const payDay = currentDate.startOf("month").add(15, "day");
    const startDate = generateDateString({ datetime: payDay, month, day: -2 });
    const endDate = generateDateString({ datetime: payDay, month, day: 2 });
    return `${startDate} al ${endDate}`;
  }
}
