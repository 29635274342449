import { Select, TextInput } from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { COUNTRY_CHILE_ID } from "../../../../api/requests/parameters";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useValidations } from "../../../../hooks/useValidations";
import { FieldsStudentNoSuaFile } from "../../../../types/studentFileNoSuaTypes";
import { getSelectedOption } from "../../../../utils/selectFormat";
import { useFilteredCommunes } from "../../../../hooks/useFilteredCommunes";
import { EducationalBackgroundType } from "../../../../types/studentFileSectionTypes";
import { useParameters } from "../../../../hooks/useParameters";

type EducationalBackgroundNoSuaProps = {
  educationalBackground?: EducationalBackgroundType;
};

/**
 * Sección de antecedentes educacionales para estudiante SIN SUA
 */
const EducationalBackgroundNoSua = ({
  educationalBackground,
}: EducationalBackgroundNoSuaProps) => {
  const prefix = "tuitionProcess.studentFile.educationalBackgroundSection";
  const { t } = useTranslation();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  } = useParameters();
  const { control, watch } = useFormContext<FieldsStudentNoSuaFile>();
  const { msgValidations, validateTextNotEmpty } = useValidations();

  const selectedCountry = watch("countryHighSchool");
  const selectedRegion = watch("regionHighSchool");
  const filteredCommunes = useFilteredCommunes(selectedRegion);

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="nameHighSchool"
            label={t(`${prefix}.highSchool`)}
            value={educationalBackground?.highSchool}
            control={control}
            maxLength={250}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="countryHighSchool"
            label={t(`${prefix}.country`)}
            options={countryOptions}
            value={getSelectedOption(
              educationalBackground?.country?.id,
              countryOptions
            )}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {selectedCountry?.value === COUNTRY_CHILE_ID && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name="regionHighSchool"
                label={t(`${prefix}.region`)}
                options={regionOptions}
                value={getSelectedOption(
                  educationalBackground?.region?.id,
                  regionOptions
                )}
                rules={{ required: msgValidations.required }}
                control={control}
                shouldUnregister={true}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name="communeHighSchool"
                label={t(`${prefix}.commune`)}
                options={filteredCommunes}
                value={getSelectedOption(
                  educationalBackground?.commune?.id,
                  communeOptions
                )}
                rules={{ required: msgValidations.required }}
                control={control}
                shouldUnregister={true}
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="typeHighSchool"
            label={t(`${prefix}.typeHighSchool`)}
            options={institutionTypeOptions}
            value={getSelectedOption(
              educationalBackground?.institutionType?.id,
              institutionTypeOptions
            )}
            rules={{ required: msgValidations.required }}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="graduationYear"
            label={t(`${prefix}.graduationYear`)}
            value={educationalBackground?.graduationYear}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            maxLength={5}
          />
        </Col>
      </Row>
    </>
  );
};

export default EducationalBackgroundNoSua;
