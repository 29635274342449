import { TextInput } from "@octano/global-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { SectionTitle } from "../../../../components/text";
import { TariffData } from "../../../../types/tariffTypes";
import { moneyFormatter } from "../../../../utils/currency";

interface ITariffQuotaDisplayDataProps {
  tariffData: TariffData;
  children: JSX.Element;
}

export default function TariffDisplayData({
  tariffData,
  children,
}: ITariffQuotaDisplayDataProps) {
  const prefix = "tuitionProcessNoSua.tariffQuotas";
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.displayData.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.displayData.studyPlan`)}
            disabled
            value={
              tariffData.studyPlan.studyPlanVersionOffer.studyPlanVersion?.name
            }
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.displayData.campus`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.campus?.name}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.displayData.schedule`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.schedule?.name}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="tariff"
            label={t(
              `${prefix}.displayData.${
                tariffData.studyPlan.tariff.hasDiscount
                  ? "discountedTariff"
                  : "tariff"
              }`
            )}
            disabled
            value={moneyFormatter().format(tariffData.studyPlan.tariff.value)}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="payDay"
            label={t(`${prefix}.displayData.payDay`)}
            disabled
            value={tariffData.payday.description}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          {children}
        </Col>
      </Row>
    </>
  );
}
