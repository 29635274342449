import { useState, useEffect, useCallback } from "react";
import { Card, Col, Form, Row } from "reactstrap";
import {
  addToast,
  Button,
  OutlinedSelect,
  SelectOptionType,
} from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import DisplayError from "../../../components/info/DisplayError";
import Loading from "../../../components/info/Loading";
import {
  getStudyPlanQuota,
  requestSaveCareerSelection,
} from "../../../api/requests/tuitionProcess";
import { useUserState } from "../../../hooks/useUserState";
import { useValidations } from "../../../hooks/useValidations";
import { useLoadingState } from "../../../hooks/useLoadingState";
import { useStepState } from "../../../hooks/useStepState";

type InputsForm = {
  career: SelectOptionType;
};

const SelectCareer = () => {
  const { t } = useTranslation();
  const prefix = "tuitionProcessNoSua.selectCareer";
  const { fullName, setStatusPostulation } = useUserState();
  const { handleSubmit, control, formState, getValues } = useForm<InputsForm>();
  const { msgValidations } = useValidations();
  const [optionsCareer, setOptionsCareer] = useState<SelectOptionType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [confirming, setConfirming] = useState<boolean>(false);
  const { nextStep } = useStepState();

  const getCareers = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStudyPlanQuota();
    if (data) {
      setOptionsCareer(
        data.data.map((career: any) => {
          return {
            value: career.id,
            label: `${career.studyPlanVersion.name} - Campus ${career.campus.name} - ${career.schedule.name}`,
          };
        })
      );
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading("error");
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getCareers();
  }, [getCareers]);

  const onSubmit = (values: InputsForm) => {
    saveSelectionCareer(values.career.value, false);
  };

  const saveSelectionCareer = async (
    admissionId: number | string,
    acceptsWaitingList: boolean
  ) => {
    const { data, error } = await requestSaveCareerSelection({
      admissionId:
        typeof admissionId === "string" ? parseInt(admissionId) : admissionId,
      acceptsWaitingList,
    });
    if (data) {
      addToast({
        icon: "success",
        color: "success",
        text: t(`${prefix}.saveSuccess`),
      });
      if (acceptsWaitingList) {
        setStatusPostulation("IN_WAITING_LIST");
      }
      nextStep();
    } else if (error) {
      if (
        error.data?.message?.[0] === "QUOTA_EXCEEDED" &&
        !acceptsWaitingList
      ) {
        // Si excede la cuota y la confirmación es false
        // se abre modal para confirmar quedar en lista de espera
        setIsModalOpen(true);
      } else {
        // TODO: Falta mensaje para error "INVALID_REGISTRATION"
        // En que casos ocurre este error?
        addToast({
          icon: "error",
          color: "danger",
          text: t(`${prefix}.saveError`),
        });
      }
    }
  };

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = async () => {
    setConfirming(true);
    const admissionId = getValues("career").value;
    await saveSelectionCareer(
      typeof admissionId === "string" ? parseInt(admissionId) : admissionId,
      true
    );
    setIsModalOpen(false);
    setConfirming(false);
  };

  if (loading && !errorLoading) {
    return <Loading insideCard />;
  }
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={t("common.errorLoadingInfo")}
        retryAction={getCareers}
        loadingAction={loading}
      />
    );
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{fullName}</span>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="py-3">
            <p>{t(`${prefix}.body`)}</p>
          </Col>
          <Col xs={12}>
            <div className="w-100 mx-auto" style={{ maxWidth: "600px" }}>
              <span className="fs-14 text-medium">{t(`${prefix}.career`)}</span>
              <OutlinedSelect
                name={"career"}
                options={optionsCareer}
                control={control}
                rules={{ required: msgValidations.required }}
                disabled={formState.isSubmitting}
              />
            </div>
          </Col>
        </Row>

        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>

      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        title={t(`${prefix}.modalWaitingList.title`)}
        body={t(`${prefix}.modalWaitingList.body`)}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.modalWaitingList.primaryBtn`),
          action: acceptWaitingList,
        }}
        secondaryBtn={{
          text: t(`${prefix}.modalWaitingList.secondaryBtn`),
          action: () => setIsModalOpen(false),
        }}
      />
    </Card>
  );
};

export default SelectCareer;
