import { useCallback, useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { UserActionTypes } from "../types/userTypes";
import { removeJWT } from "../utils/auth";

/**
 * Provee el estado y el manejo de estado del usuario autenticado
 */
export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext);

  const resetUserState = useCallback(async () => {
    removeJWT();
    dispatch({
      type: UserActionTypes.RESET_STATE,
    });
  }, [dispatch]);

  const setIsLogged = useCallback(
    (isLogged: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_LOGGED,
        payload: isLogged,
      });
    },
    [dispatch]
  );

  const setIsSessionExpired = useCallback(
    (isSessionExpired: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_SESSION_EXPIRED,
        payload: isSessionExpired,
      });
    },
    [dispatch]
  );

  const setUserFullName = useCallback(
    (fullName: string) => {
      dispatch({
        type: UserActionTypes.SET_FULL_NAME,
        payload: fullName,
      });
    },
    [dispatch]
  );

  const setUserRut = useCallback(
    (rut: string) => {
      dispatch({
        type: UserActionTypes.SET_USER_RUT,
        payload: rut,
      });
    },
    [dispatch]
  );

  const setStatusPostulation = useCallback(
    (status: string) => {
      dispatch({
        type: UserActionTypes.SET_STATUS_POSTULATION,
        payload: status,
      });
    },
    [dispatch]
  );

  return {
    ...state,
    resetUserState,
    setIsLogged,
    setIsSessionExpired,
    setUserFullName,
    setUserRut,
    setStatusPostulation,
  };
};
