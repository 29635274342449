import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Sustainer from "../views/TuitionProcess/parts/Sustainer/Sustainer";
import CompletedNoSua from "../views/TuitionProcessNoSua/parts/Completed/CompletedNoSua";
import DocumentationNoSua from "../views/TuitionProcessNoSua/parts/Documentation/DocumentationNoSua";
import SelectCareer from "../views/TuitionProcessNoSua/parts/SelectCareer";
import StudentFileNoSuaStep from "../views/TuitionProcessNoSua/parts/StudentFile/StudentFileNoSuaStep";
import Tariff from "../views/TuitionProcessNoSua/parts/tariff/Tariff";
import { useStepState } from "./useStepState";

export const useTuitionProcessNoSuaSteps = () => {
  const { t } = useTranslation();
  const prefix = "tuitionProcessNoSua";
  const { currentStep } = useStepState();

  /**
   * Contiene los pasos del proceso de matrícula SIN SUA
   */
  const stepsTuitionProcess = useMemo(() => {
    return [
      {
        id: "study-plan-selection",
        name: t(`${prefix}.steps.selectCareer`),
        component: SelectCareer,
      },
      {
        id: "student-file",
        name: t(`${prefix}.steps.studentFile`),
        component: StudentFileNoSuaStep,
      },
      {
        id: "sustainer",
        name: t(`${prefix}.steps.tuitionPayer`),
        component: Sustainer,
      },
      {
        id: "tariff",
        name: t(`${prefix}.steps.tariffQuotas`),
        component: Tariff,
      },
      {
        id: "sign-documentation",
        name: t(`${prefix}.steps.sign`),
        component: DocumentationNoSua,
      },
      {
        id: "completed",
        name: t(`${prefix}.steps.completed`),
        component: CompletedNoSua,
      },
    ];
  }, [t]);

  /**
   * Contiene dos arrays con las partes de los pasos
   * (Un array con los nombres de los pasos y
   * un array con los componentes asociados a cada paso)
   */
  const availableParts = useMemo(() => {
    const namesSteps: string[] = [];
    const componentsSteps: (React.FunctionComponent | undefined)[] = [];
    stepsTuitionProcess.forEach((step) => {
      namesSteps.push(step.name);
      componentsSteps.push(step.component);
    });
    return { namesSteps: namesSteps.slice(0, -1), componentsSteps };
  }, [stepsTuitionProcess]);

  const getIndexStep = useCallback(
    (id: string) => {
      return stepsTuitionProcess.findIndex((step) => step.id === id);
    },
    [stepsTuitionProcess]
  );

  /**
   * El paso actual en base a la variable currentStep
   */
  const CurrentStepComponent = useMemo(() => {
    return availableParts.componentsSteps[currentStep];
  }, [currentStep, availableParts]);

  return {
    stepsTuitionProcess,
    ...availableParts,
    CurrentStepComponent,
    getIndexStep,
  };
};
