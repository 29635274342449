import {
  Button,
  CheckInput,
  DateInput,
  Select,
  SelectOptionType,
  TextInput,
} from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useParameters } from "../../../hooks/useParameters";
import { useValidations } from "../../../hooks/useValidations";

export type RegistrationInputs = {
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: Date;
  hasPassport: boolean;
  rut: string; 
  passportNumber: string;
  passportCountry: SelectOptionType;
  phone: string;
  email: string;
};

const RegistrationForm = () => {
  const prefix = "registration.form";
  const { t } = useTranslation();
  const { countryOptions } = useParameters();

  const { control, watch } = useFormContext<RegistrationInputs>();
  const {
    msgValidations,
    validateRut,
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();

  const hassPassport = watch("hasPassport");

  return (
    <Row className="px-4 pt-4">
      <Col xs={12} className="pt-2 pb-4 text-center">
        <p>{t(`${prefix}.body`)}</p>
      </Col>
      <Col xs={12}>
        <TextInput
          name="names"
          label={t(`${prefix}.names`)}
          control={control}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>
      <Col xs={12}>
        <TextInput
          name="paternalLastName"
          label={t(`${prefix}.paternalLastName`)}
          control={control}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>
      <Col xs={12}>
        <TextInput
          name="maternalLastName"
          label={t(`${prefix}.maternalLastName`)}
          control={control}
          rules={{ validate: validateTextNotEmpty }}
        />
      </Col>

      {!hassPassport ? (
        <Col xs={12}>
          <TextInput
            name="rut"
            label={t(`${prefix}.rut`)}
            control={control}
            formatter="rut"
            shouldUnregister={true}
            rules={{
              validate: {
                empty: validateTextNotEmpty,
                rut: validateRut,
              },
            }}
          />
        </Col>
      ) : (
        <>
          <Col xs={12} md={6} className="pr-md-1">
            <Select
              name="passportCountry"
              label={t(`${prefix}.passportCountry`)}
              control={control}
              options={countryOptions}
              shouldUnregister={true}
              isClearable={false}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col xs={12} md={6} className="pl-md-1">
            <TextInput
              name="passportNumber"
              label={t(`${prefix}.passportNumber`)}
              control={control}
              shouldUnregister={true}
              rules={{ validate: validateTextNotEmpty }}
            />
          </Col>
        </>
      )}

      <Col xs={12}>
        <CheckInput
          name="hasPassport"
          label={"Ingresar Pasaporte"}
          control={control}
        />
      </Col>
      <Col xs={12}>
        <DateInput
          name="birthday"
          label={t(`${prefix}.birthday`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12}>
        <TextInput
          name="phone"
          label={t(`${prefix}.phone`)}
          control={control}
          formatter="phone"
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              phone: validatePhone,
            },
          }}
          placeholder={t(`common.placeholder.cellphone`)}
        />
      </Col>
      <Col xs={12}>
        <TextInput
          name="email"
          label={t(`${prefix}.email`)}
          control={control}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              email: validateEmail,
            },
          }}
        />
      </Col>
      <Col xs={12} className="pt-4">
        <Button type="submit" text={t(`${prefix}.registrationBtn`)} fullwidth />
      </Col>
    </Row>
  );
};

export default RegistrationForm;
