import { addToast, Button } from "@octano/global-ui";
import { DefaultValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "reactstrap";
import { saveStudentFile } from "../../../../api/requests/tuitionProcess";
import ContactSection from "../../../../components/form/ContactSection";
import { useStepState } from "../../../../hooks/useStepState";
import { useUserState } from "../../../../hooks/useUserState";
import { FieldsStudentNoSuaFile } from "../../../../types/studentFileNoSuaTypes";
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from "../../../../types/studentFileSectionTypes";
import EmergencyContactSection from "../../../TuitionProcess/parts/StudentFile/EmergencyContactSection";
import HealthCareSection from "../../../TuitionProcess/parts/StudentFile/HealthCareSection";
import ParentalEducationSection from "../../../TuitionProcess/parts/StudentFile/ParentalEducationSection";
import EducationalBackgroundNoSua from "./EducationalBackgroundNoSua";
import StudentBackgroundNoSua from "./StudentBackgroundNoSua";

type StudentFileNoSuaFormProps = {
  backgroundData?: BackgroundSectionType;
  educationalBackground?: EducationalBackgroundType;
  defaultValues?: DefaultValues<FieldsStudentNoSuaFile>;
};

const StudentFileNoSuaForm = ({
  backgroundData,
  educationalBackground,
  defaultValues,
}: StudentFileNoSuaFormProps) => {
  const { nextStep } = useStepState();
  const prefix = "tuitionProcess.studentFile";
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const methods = useForm<FieldsStudentNoSuaFile>({
    mode: "onSubmit",
    defaultValues: defaultValues,
  });

  const onSubmit = async (values: FieldsStudentNoSuaFile) => {
    if (
      values.commune &&
      values.communeFamily &&
      values.countryHighSchool &&
      values.typeHighSchool
    ) {
      const { data, error } = await saveStudentFile({
        background: {
          sexId: +values.sexID.value,
          maritalStatusId: +values.maritalStatus.value,
          nationalityId: values.nationality.value.toString(),
        },
        contact: {
          addressStreet: values.addressStreet,
          addressNumber: values.addressNumber,
          addressExtra: values.addressExtra,
          communeId: +values.commune.value,
          phone: values.phone,
          cellPhone: values.cellphone,
          email: values.email,
        },
        familyContact: {
          addressStreet: values.addressStreetFamily,
          addressNumber: values.addressNumberFamily,
          addressExtra: values.addressExtraFamily,
          communeId: +values.communeFamily.value,
          phone: values.phoneFamily,
          cellPhone: values.cellphoneFamily,
          email: values.emailFamily,
        },
        emergencyContact: {
          name: values.emergencyNames,
          cellPhone: values.mobilePhoneNumberEmergency,
          email: values.emailEmergency,
        },
        healthCare: {
          systemId: +values.healthCareSystem.value,
          providerId: values?.healthCareProvider
            ? +values.healthCareProvider.value
            : null,
          contributorRut: values.healthCareContributorID,
          hasInsurance: Boolean(values.healthInsurance.value),
          insuranceContributorRut: values.healthInsuranceContributorID ?? null,
          insuranceCellPhone: values.phoneNumberHealthCare,
          otherSystem: values.otherHealthCare,
        },
        parentalEducationalBackground: {
          educationalLevelPaternalId: +values.fatherEducationalLevel.value,
          educationalLevelMaternalId: +values.motherEducationalLevel.value,
          familyGroupId: +values.familyGroup.value,
          parentalSurvivalId: +values.parentalSurvival.value,
        },
        postulantEducationalBackground: {
          highSchool: values.nameHighSchool,
          graduationYear: +values.graduationYear,
          countryId: values.countryHighSchool.value.toString(),
          communeId: values.communeHighSchool
            ? +values.communeHighSchool.value
            : null,
          institutionTypeId: +values.typeHighSchool.value,
        },
      });
      if (data === "") {
        addToast({
          icon: "success",
          color: "success",
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        if (error.code === "HTTP_ERROR" && error.status === 401) {
          setIsSessionExpired(true);
        } else {
          addToast({
            icon: "error",
            color: "danger",
            text: t(`${prefix}.saveError`),
          });
        }
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        {/* SECCIONES DEL FORMULARIO */}
        <StudentBackgroundNoSua data={backgroundData} />
        <EducationalBackgroundNoSua
          educationalBackground={educationalBackground}
        />
        <ContactSection prefix={`${prefix}.academicContactSection`} />
        <ContactSection
          prefix={`${prefix}.familyGroupSection`}
          fieldNames={{
            addressStreet: "addressStreetFamily",
            addressNumber: "addressNumberFamily",
            addressExtra: "addressExtraFamily",
            region: "regionFamily",
            commune: "communeFamily",
            phone: "phoneFamily",
            cellphone: "cellphoneFamily",
            email: "emailFamily",
          }}
        />
        <EmergencyContactSection />
        <ParentalEducationSection />
        <HealthCareSection />

        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={methods.formState.isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default StudentFileNoSuaForm;
