import { Select, TextInput } from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Row } from "reactstrap";
import { format as formatRut } from "rut.js";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useValidations } from "../../../../hooks/useValidations";
import { formatDate } from "../../../../utils/dates";
import { getSelectedOption } from "../../../../utils/selectFormat";
import {
  maritalStatusOptions,
  sexCIOptions,
} from "../../../../utils/selectOptions";
import { BackgroundSectionType } from "../../../../types/studentFileSectionTypes";
import { useParameters } from "../../../../hooks/useParameters";
import StudentPhoto, {
  StudentPhotoTexts,
} from "../../../TuitionProcess/parts/StudentFile/StudentPhoto";
import { FieldsStudentNoSuaFile } from "../../../../types/studentFileNoSuaTypes";

type StudentBackgroundNoSuaProps = {
  data: BackgroundSectionType | undefined;
};

/**
 * Sección de Antecedentes del estudiante SIN SUA
 */
const StudentBackgroundNoSua = ({ data }: StudentBackgroundNoSuaProps) => {
  const { control } = useFormContext<FieldsStudentNoSuaFile>();

  const prefix = `tuitionProcess.studentFile.backgroundSection`;
  const { t } = useTranslation();
  const { msgValidations } = useValidations();
  const { countryOptions, nationalityOptions } = useParameters();

  const studentPhotoTexts = t<any, StudentPhotoTexts>(
    `tuitionProcess.studentFile.studentPhoto`,
    { returnObjects: true }
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: "121px" }}>
              <StudentPhoto
                texts={studentPhotoTexts}
                defaultPhoto={data?.student.photo}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={6}>
              {data?.student.rut ? (
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  formatter="rut"
                  value={formatRut(data?.student.rut)}
                  defaultValue={formatRut(data.student.rut)}
                  disabled
                  shouldUnregister={true}
                />
              ) : (
                <Row>
                  <Col xs={5} className="pr-1 pr-md-2">
                    <Select
                      name="passportCountry"
                      label={t(`${prefix}.passportCountry`)}
                      options={countryOptions}
                      value={getSelectedOption(
                        data?.student?.passport?.countryId,
                        countryOptions
                      )}
                      defaultValue={getSelectedOption(
                        data?.student?.passport?.countryId,
                        countryOptions
                      )}
                      shouldUnregister={true}
                      isClearable={false}
                      disabled
                    />
                  </Col>
                  <Col xs={7} className="pl-1 pl-md-2">
                    <TextInput
                      name="passportNumber"
                      label={t(`${prefix}.passportNumber`)}
                      value={data?.student?.passport?.number}
                      defaultValue={data?.student.passport?.number}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="namesStudent"
                label={t(`${prefix}.names`)}
                value={data?.student.names}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="paternalSurname"
                label={t(`${prefix}.paternalLastName`)}
                value={data?.student.paternalName}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name="maternalSurname"
                label={t(`${prefix}.maternalLastName`)}
                value={data?.student.maternalName}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="cv"
            label={t(`${prefix}.cv`)}
            value={data?.studyPlan.code}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.studyPlan`)}
            value={data?.studyPlan.name}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.campus`)}
            value={data?.studyPlan.campus}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.schedule`)}
            value={data?.studyPlan.schedule}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="entryYear"
            label={t(`${prefix}.entryYear`)}
            value={data?.postulation.entryYear}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            value={getSelectedOption(
              data?.student.nationality?.id,
              nationalityOptions
            )}
            control={control}
            options={nationalityOptions}
            isSearchable={true}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="birthday"
            label={t(`${prefix}.birthday`)}
            value={
              data?.student.birthday
                ? formatDate(data?.student.birthday, "DD-MM-YYYY")
                : ""
            }
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="sexID"
            label={t(`${prefix}.sexID`)}
            options={sexCIOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
      </Row>
    </>
  );
};

export default StudentBackgroundNoSua;
