import { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, SelectOptionType } from "@octano/global-ui";
import { getCountriesRequest } from "../../api/requests/parameters";
import { formatCountryOptions } from "../../utils/selectFormat";
import { useForm } from "react-hook-form";
import LoginFormFields, { LoginInputs } from "./LoginFormFields";

import { ReactComponent as AddemicProductLogo } from "../../assets/addemic/logo_expanded.svg";
import { IS_ADDEMIC } from "../../config/constants";

interface LoginFormProps {
  errorLogin: string;
  toggleError: () => void;
  onLogin: (values: LoginInputs) => void;
}

const LoginForm = ({ errorLogin, toggleError, onLogin }: LoginFormProps) => {
  const prefix = "loginTuitionProcess";
  const { t } = useTranslation();

  const [countries, setCountries] = useState<SelectOptionType[]>([]);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<LoginInputs>({
    mode: "onChange",
    defaultValues: {
      hasPassport: false,
    },
  });

  const getCountries = async () => {
    const { data, error } = await getCountriesRequest();
    if (data) {
      setCountries(formatCountryOptions(data));
    } else if (error) {
      // TODO: Mostrar error al cargar datos
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Row className="py-5">
      <Col xs={12} className="text-center">
        {IS_ADDEMIC ? (
          <AddemicProductLogo style={{ maxHeight: 80 }} />
        ) : (
          <img
            src="/tenant/logo_expanded.svg"
            alt="tenant_logo"
            style={{ maxHeight: 100 }}
          />
        )}
      </Col>
      <Col xs={12}>
        <p className="text-center py-4">
          <Trans t={t}>{t(`${prefix}.welcome`)}</Trans>
        </p>
      </Col>

      <Col xs={12}>
        <Alert
          color="danger"
          icon="error"
          noFill
          size="sm"
          text={errorLogin !== "" ? t(`${prefix}.${errorLogin}`) : ""}
          isOpen={errorLogin !== ""}
          toggle={toggleError}
        />
      </Col>

      <Col xs={12}>
        <Form onSubmit={handleSubmit(onLogin)}>
          <LoginFormFields
            hassPassport={watch("hasPassport", false)}
            countries={countries}
            control={control}
          />
          <div className="pt-4">
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={t(`${prefix}.loginBtn`)}
              fullwidth
            />
          </div>
        </Form>
      </Col>
      {/*
      <Col xs={12} className="pb-1 text-center">
        <div className="mb-0 text-center text-underline color-primary">
          {t(`${prefix}.howToLogin`)}
        </div>
      </Col>
      */}
    </Row>
  );
};

export default LoginForm;
