import AuthLayout from "../layouts/AuthLayout";
import TutionProcessLayout from "../layouts/TutionProcessLayout";
import AuthTuitionProcessNoSua from "../views/AuthTuitionProcessNoSua/AuthTuitionProcessNoSua";
import AuthTuitionProcess from "../views/AuthTuitionProcess/AuthTuitionProcess";
import TuitionProcess from "../views/TuitionProcess/TuitionProcess";
import { IS_SUA } from "./constants";
import TuitionProcessNoSua from "../views/TuitionProcessNoSua/TuitionProcessNoSua";
import RegistrationNoSua from "../views/RegistrationNoSua/RegistrationNoSua";
import CertificateValidationLayout from "../layouts/CertificateValidationLayout";
import ValidationForm from "../views/CertificateValidation/ValidationForm";
import CertificateDetails from "../views/CertificateValidation/CertificateDetails";

export enum PathsLayouts {
  auth = "/auth",
  tuitionProcess = "/tuition-process",
  certificateValidation= "/validacioncertificado"
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: AuthLayout,
    defaultPath: "/tuition-process",
    views: [
      {
        path: "/tuition-process",
        component: IS_SUA ? AuthTuitionProcess : AuthTuitionProcessNoSua,
      },
      {
        path: "/registration",
        component: RegistrationNoSua,
      },
    ],
  },
  [PathsLayouts.tuitionProcess]: {
    layoutComponent: TutionProcessLayout,
    defaultPath: "/steps",
    views: [
      {
        path: "/steps",
        component: IS_SUA ? TuitionProcess : TuitionProcessNoSua,
      },
    ],
  },
  [PathsLayouts.certificateValidation]: {
    layoutComponent: CertificateValidationLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: ValidationForm ,
      },
      {
        path: "/detalle",
        component: CertificateDetails ,
      },
    ],
  },
};

export default routes;
