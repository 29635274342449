import { SelectOptionType } from "@octano/global-ui";
import { DefaultValues } from "react-hook-form";
import { format as formatRut } from "rut.js";
import { FieldsStudentNoSuaFile } from "../types/studentFileNoSuaTypes";
import { StudentFileResponse } from "../types/studentFileSectionTypes";
import { getSelectedOption } from "./selectFormat";
import {
  educationalLevelOptions,
  familyGroupOptions,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  maritalStatusOptions,
  parentalSurvivalOptions,
  sexCIOptions,
} from "./selectOptions";

export const formatCommonDefaultValuesStudentFile = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[]
) => {
  const selectedCommune = getSelectedOption(
    data.contact?.communeId,
    communeOptions
  );
  const selectedCommuneFamily = getSelectedOption(
    data.familyContact?.communeId,
    communeOptions
  );

  return {
    sexID: getSelectedOption(data.student.sexId ?? undefined, sexCIOptions),
    maritalStatus: getSelectedOption(
      data.student.maritalStatusId ?? undefined,
      maritalStatusOptions
    ),
    addressStreet: data.contact?.addressStreet,
    addressNumber: data.contact?.addressNumber,
    addressExtra: data.contact?.addressExtra ?? undefined,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    commune: selectedCommune,
    phone: data.contact?.phone ?? undefined,
    cellphone: data.contact?.cellPhone,
    email: data.contact?.email,
    addressStreetFamily: data.familyContact?.addressStreet,
    addressNumberFamily: data.familyContact?.addressNumber,
    addressExtraFamily: data.familyContact?.addressExtra ?? undefined,
    regionFamily: getSelectedOption(
      selectedCommuneFamily?.regionId,
      regionOptions
    ),
    communeFamily: selectedCommuneFamily,
    phoneFamily: data.familyContact?.phone ?? undefined,
    cellphoneFamily: data.familyContact?.cellPhone,
    emailFamily: data.familyContact?.email,
    emergencyNames: data.emergencyContact?.name,
    mobilePhoneNumberEmergency: data.emergencyContact?.cellPhone,
    emailEmergency: data.emergencyContact?.email,
    fatherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelPaternalId,
      educationalLevelOptions
    ),
    motherEducationalLevel: getSelectedOption(
      data.parentalEducationalBackground?.educationalLevelMaternalId,
      educationalLevelOptions
    ),
    familyGroup: getSelectedOption(
      data.parentalEducationalBackground?.familyGroupId,
      familyGroupOptions
    ),
    parentalSurvival: getSelectedOption(
      data.parentalEducationalBackground?.parentalSurvivalId,
      parentalSurvivalOptions
    ),
    healthCareSystem: getSelectedOption(
      data.healthCare?.systemId,
      healthCareSystemOptions
    ),
    healthCareProvider: getSelectedOption(
      data.healthCare?.providerId ?? undefined,
      healthCareProviderOptions
    ),
    healthCareContributorID: data.healthCare?.contributorRut
      ? formatRut(data.healthCare.contributorRut)
      : undefined,
    healthInsurance: getSelectedOption(
      data.healthCare?.hasInsurance !== undefined
        ? +data.healthCare?.hasInsurance
        : undefined,
      healthInsuranceOptions
    ),
    healthInsuranceContributorID: data.healthCare?.insuranceContributorRut
      ? formatRut(data.healthCare.insuranceContributorRut)
      : undefined,
    phoneNumberHealthCare: data.healthCare?.insuranceCellPhone ?? undefined,
    otherHealthCare: data.healthCare?.otherSystem,
  };
};

export const formatDefaultValuesStudentFileNoSua = (
  data: StudentFileResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countries: SelectOptionType[],
  institutionTypes: SelectOptionType[]
): DefaultValues<FieldsStudentNoSuaFile> => {
  return {
    ...formatCommonDefaultValuesStudentFile(
      data,
      communeOptions,
      regionOptions
    ),
    nationality: getSelectedOption(data.student?.nationality?.id, countries),

    nameHighSchool: data.educationalBackground.highSchool,
    countryHighSchool: getSelectedOption(
      data.educationalBackground?.country?.id,
      countries
    ),
    regionHighSchool: getSelectedOption(
      data.educationalBackground?.region?.id,
      regionOptions
    ),
    communeHighSchool: getSelectedOption(
      data.educationalBackground?.commune?.id,
      communeOptions
    ),
    typeHighSchool: getSelectedOption(
      data.educationalBackground.institutionType?.id,
      institutionTypes
    ),
    graduationYear: data.educationalBackground.graduationYear,
  };
};
