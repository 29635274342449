import { Button, Icon, IconPaths, Modal } from "@octano/global-ui";
import { Col, Row } from "reactstrap";

interface BtnProps {
  text: string;
  action: () => void;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  toggle: () => void;
  closeOnBackdrop?: boolean;
  iconName?: keyof typeof IconPaths;
  title: string;
  body?: string;
  primaryBtn: BtnProps;
  secondaryBtn?: BtnProps;
  loading?: boolean;
}

const ConfirmationModal = ({
  isOpen,
  toggle,
  closeOnBackdrop = false,
  iconName = "information",
  title,
  body,
  primaryBtn,
  secondaryBtn,
  loading = false,
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} closeOnBackdrop={closeOnBackdrop}>
      <Row className="text-center">
        <Col xs={12} className="pt-2">
          <Icon name={iconName} size="56px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 pb-3">
          <span className="fs-22 text-dark">{title}</span>
        </Col>
        {body && (
          <Col xs={12}>
            <p className="fs-18 fw-300">{body}</p>
          </Col>
        )}
      </Row>
      <Row className="pt-3">
        {secondaryBtn && (
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              outlined
              onClick={secondaryBtn.action}
              text={secondaryBtn.text}
              disabled={loading}
              fullwidth
            />
          </Col>
        )}
        <Col
          xs={12}
          sm={secondaryBtn ? 6 : 12}
          className="pb-2 px-3 px-sm-2 mx-auto"
        >
          <Button
            onClick={primaryBtn.action}
            text={primaryBtn.text}
            loading={loading}
            disabled={loading}
            fullwidth
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
