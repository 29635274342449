import { addToast, Alert, Button, IconPaths } from "@octano/global-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import {
  getSignDocumentation,
  verifySignDocumentation,
} from "../../../../api/requests/tuitionProcess";
import DisplayError from "../../../../components/info/DisplayError";
import Loading from "../../../../components/info/Loading";
import { SectionTitle } from "../../../../components/text";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import { useStepState } from "../../../../hooks/useStepState";
import { useUserState } from "../../../../hooks/useUserState";
import {
  DocumentTypes,
  IdentityTypes,
  SignDocumentationStepResponse,
} from "../../../../types/signDocumentationTypes";
import Document from "./Document";
import DocumentModal from "./DocumentModal";
import IdentityCards from "./IdentityCards";

type DocumentInfo = {
  id: DocumentTypes;
  name: string;
  icon: keyof typeof IconPaths;
  isSigned: boolean;
};

const formatAvailableDocuments = (
  data: SignDocumentationStepResponse
): DocumentInfo[] => {
  const documentData: Record<string, any> = {
    "promissory-note": {
      name: "promissoryNote",
      icon: "promissory_note",
    },
  };
  return data.documents.map((document) => {
    return {
      id: document.type,
      ...documentData[document.type],
      isSigned: document.isSigned,
    };
  });
};

type IdentityCardsInfo = {
  id: IdentityTypes;
  isUploaded: boolean;
};

const formatAvailableIdentityCards = (
  data: SignDocumentationStepResponse
): IdentityCardsInfo[] => {
  return data.identityCards.map((identityCard) => {
    return {
      id: identityCard.type,
      isUploaded: identityCard.isUploaded,
    };
  });
};

function useSignErrors(
  documents: DocumentInfo[],
  identityCards: IdentityCardsInfo[]
) {
  const documentErrors = new Map<DocumentTypes, boolean>();
  const identityCardsErrors = new Map<IdentityTypes, boolean>();

  for (const document of documents) {
    if (!document.isSigned) {
      documentErrors.set(document.id, true);
    }
  }
  for (const identityCard of identityCards) {
    if (!identityCard.isUploaded) {
      identityCardsErrors.set(identityCard.id, true);
    }
  }

  return {
    documents: documentErrors,
    identityCards: identityCardsErrors,
  };
}

const Sign = () => {
  const prefix = "tuitionProcess.sign";
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { fullName, setIsSessionExpired } = useUserState();
  const { nextStep } = useStepState();
  const [docToSign, setDocToSign] = useState<DocumentTypes>();

  const [availableDocuments, setAvailableDocuments] = useState<DocumentInfo[]>(
    []
  );

  const [availableIdentityCards, setAvailableIdentityCards] = useState<
    IdentityCardsInfo[]
  >([]);

  const [isAlertOpen, setIsAlertOpen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showErrors, setShowErrors] = useState(false);
  const errors = useSignErrors(availableDocuments, availableIdentityCards);

  const getStatus = useCallback(async () => {
    setShowErrors(false);
    const { data, error } = await getSignDocumentation();
    if (data) {
      setAvailableDocuments(formatAvailableDocuments(data));
      setAvailableIdentityCards(formatAvailableIdentityCards(data));
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, setIsSessionExpired]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onDocumentModalClose = useCallback(
    async (signed?: boolean) => {
      if (signed) {
        await getStatus();
      }
      setDocToSign(undefined);
    },
    [getStatus]
  );

  const onSubmit = async () => {
    if (errors.documents.size || errors.identityCards.size) {
      setShowErrors(true);
      return;
    }
    const { error } = await verifySignDocumentation();
    if (!error) {
      nextStep();
    } else if (error && error.code === "HTTP_ERROR" && error.status === 401) {
      setIsSessionExpired(true);
    } else {
      addToast({
        icon: "error",
        color: "danger",
        text: t(`${prefix}.nextStepError`),
      });
    }
  };

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        toggle={() => setIsAlertOpen(false)}
        text={t(`${prefix}.infoSign`)}
        icon="information"
        fade
      />
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col className="pb-3" xs={12} lg={7} md={8} xl={9}>
            <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
            <br />
            <span className="fs-18 text-primary fw-600">{fullName}</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
        </Row>
        <Row>
          {/* CONTAINERS CON DOCUMENTOS DISPONIBLES */}
          {availableDocuments.map((doc, i) => {
            return (
              //  TODO: Agregar acciones para ver y descargar
              <Document
                key={`${doc.name}_${i}`}
                name={t(`${prefix}.${doc.name}.name`)}
                nameIcon={doc.icon}
                isSigned={doc.isSigned}
                btnOpen={{
                  text: t(`${prefix}.${doc.name}.btnOpen`),
                  action: () => 1,
                }}
                btnDownload={{
                  text: t(`${prefix}.${doc.name}.btnDownload`),
                  action: () => 1,
                }}
                btnSign={{
                  text: t(
                    `${prefix}.${doc.name}.${
                      doc.isSigned ? "btnSigned" : "btnSign"
                    }`
                  ),
                  action: () => setDocToSign(doc.id),
                }}
                error={
                  showErrors && errors.documents.has(doc.id)
                    ? t(`${prefix}.errors.${doc.name}`)
                    : undefined
                }
              />
            );
          })}
          {Boolean(availableIdentityCards.length) && (
            <Col xs={12} lg={6} className="mb-3">
              <IdentityCards
                identityCards={availableIdentityCards}
                error={
                  showErrors && errors.identityCards.size > 0
                    ? t(`${prefix}.errors.identityCards`)
                    : undefined
                }
                onUploaded={() => getStatus()}
              />
            </Col>
          )}
        </Row>
        <Row className="pb-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              text={t(`${prefix}.btnNext`)}
              onClick={() => onSubmit()}
              fullwidth
            />
          </Col>
        </Row>

        <DocumentModal docToSign={docToSign} onClose={onDocumentModalClose} />
      </Card>
    </>
  );
};

export default Sign;
