import { PARAMETERS } from "../endpoints";
import request from "../request";

export const COUNTRY_CHILE_ID = "cl";
/**
 * Obtiene listado de países
 */
export const getCountriesRequest = () => {
  return request<{ id: string; name: string; nationality: string }[]>(
    PARAMETERS.COUNTRIES
  );
};

/**
 * Obtiene listado de países
 */
export const getIntitutionTypesRequest = () => {
  return request<{ data: { id: string; name: string; description: string }[] }>(
    PARAMETERS.INSTITUTION_TYPES
  );
};

/**
 * Obtiene listado de países
 */
export const getRegionCommunesRequest = () => {
  return request<{
    regions: { id: string; name: string }[];
    communes: { id: number; name: string; regionId: number }[];
  }>(PARAMETERS.REGIONS_COMMUNES);
};
