import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { DefaultValues } from "react-hook-form";
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from "../../../../types/studentFileSectionTypes";
import DisplayError from "../../../../components/info/DisplayError";
import Loading from "../../../../components/info/Loading";
import HeaderStudentFullName from "../../../../components/tuitionProcess/HeaderStudentFullName";
import { useUserState } from "../../../../hooks/useUserState";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import StudentFileNoSuaForm from "./StudentFileNoSuaForm";
import { FieldsStudentNoSuaFile } from "../../../../types/studentFileNoSuaTypes";
import { getStudentFile } from "../../../../api/requests/tuitionProcess";
import { useParameters } from "../../../../hooks/useParameters";
import { formatDefaultValuesStudentFileNoSua } from "../../../../utils/tuitionProcess";

/**
 * Paso "Ficha Alumno" del Formulario de proceso de matrícula
 */
const StudentFileNoSua = () => {
  const prefix = "tuitionProcess.studentFile";
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [admissionType, setAdmissionType] =
    useState<{ id: string; name: string }>();
  const [backgroundData, setBackgroundData] = useState<BackgroundSectionType>();
  const [educationalBackground, setEducationalBackground] =
    useState<EducationalBackgroundType>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsStudentNoSuaFile>>();
  const {
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  } = useParameters();

  const getStudentFileInfo = useCallback(async () => {
    const { data, error } = await getStudentFile();
    if (data) {
      setDefaultValues(
        formatDefaultValuesStudentFileNoSua(
          data,
          communeOptions,
          regionOptions,
          countryOptions,
          institutionTypeOptions
        )
      );
      setAdmissionType(data.admissionType);
      setBackgroundData({
        student: data.student,
        studyPlan: data.studyPlan,
        postulation: data.postulation,
      });
      setEducationalBackground(data.educationalBackground);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    setIsSessionExpired,
    countryOptions,
    communeOptions,
    regionOptions,
    institutionTypeOptions,
  ]);

  useEffect(() => {
    if (
      loading &&
      countryOptions.length > 0 &&
      regionOptions.length > 0 &&
      communeOptions.length > 0 &&
      institutionTypeOptions.length > 0
    ) {
      getStudentFileInfo();
    }
  }, [
    loading,
    getStudentFileInfo,
    countryOptions,
    regionOptions,
    communeOptions,
    institutionTypeOptions,
  ]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12} lg={7} md={8} xl={9}>
          <HeaderStudentFullName prefix={prefix} />
        </Col>
        <Col xs={12} lg={5} md={4} xl={3} className="pt-3 pt-md-0">
          <div className="box-outlined w-100 ml-md-auto w-100">
            <span className="text-secondary fw-700 text-uppercase">
              {t(`${prefix}.admissionType`)}
            </span>
            <br />
            {admissionType?.id && (
              <span className="fs-16 text-secondary">
                {t(`admissionTypes.${admissionType.id}`)}
              </span>
            )}
          </div>
        </Col>
      </Row>
      <StudentFileNoSuaForm
        backgroundData={backgroundData}
        educationalBackground={educationalBackground}
        defaultValues={defaultValues}
      />
    </Card>
  );
};

export default StudentFileNoSua;
