import { useCallback, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import DisplayError from "../info/DisplayError";
import Loading from "../info/Loading";
import { useLoadingState } from "../../hooks/useLoadingState";
import { useUserState } from "../../hooks/useUserState";
import { getCompleted } from "../../api/requests/tuitionProcess";
import { ReactComponent as CompletedImage } from "../../assets/svg/completed_image.svg";

interface ProcessCompletedProps {
  prefix?: string;
}

const ProcessCompleted = ({
  prefix = "tuitionProcess.completed",
}: ProcessCompletedProps) => {
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { setIsSessionExpired } = useUserState();
  const [helpEmail, setHelpEmail] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getStatus = useCallback(async () => {
    const { data, error } = await getCompleted();
    if (data) {
      setErrorLoading(undefined);
      setHelpEmail(data.helpEmail);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, setIsSessionExpired]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <div style={{ alignSelf: "center" }}>
          <Row className="text-center py-4">
            <Col xs={12}>
              <CompletedImage width="230px" />
            </Col>
            <Col xs={12} className="py-4">
              <span className="fs-20 text-dark">{t(`${prefix}.title`)}</span>
            </Col>
            <Col xs={12}>
              <p className="fs-18 fw-300">
                <Trans
                  i18nKey={`${prefix}.body`}
                  values={{ helpEmail }}
                  components={{ emailLink: <span className="text-link" /> }}
                />
              </p>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default ProcessCompleted;
