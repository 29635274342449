import { addToast, Button } from "@octano/global-ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";

import {
  getPreviewContract,
  getSignDocumentation,
  tokenSignPreviewDocument,
  verifySignDocumentation,
} from "../../../../api/requests/tuitionProcess";
import DisplayError from "../../../../components/info/DisplayError";
import Loading from "../../../../components/info/Loading";
import { SectionTitle } from "../../../../components/text";
import HeaderStudentFullName from "../../../../components/tuitionProcess/HeaderStudentFullName";
import StepBackButton from "../../../../components/tuitionProcess/StepBackButton";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import { useStepState } from "../../../../hooks/useStepState";
import { useUserState } from "../../../../hooks/useUserState";
import {
  DocumentTypes,
  DocumentTypesEnum,
  IdentityTypes,
} from "../../../../types/signDocumentationTypes";
import DocumentCard from "./DocumentCard";

interface Identification {
  type: IdentityTypes;
  isUploaded: boolean;
}
interface Document {
  type: DocumentTypes;
  isSigned: boolean;
}

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const DocumentationNoSua = () => {
  const prefix = "tuitionProcessNoSua.documentation";
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { setIsSessionExpired } = useUserState();
  const { nextStep } = useStepState();

  const [documents, setDocuments] = useState<Document[]>([]);
  const [identifications, setIdentifications] = useState<Identification[]>([]);
  const [documentsOthers, setDocumentsOthers] = useState<Document[]>([]);

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const requiredMsgError = t(`${prefix}.errorRequired`);

  const findDocumentOthers = useCallback(
    (type: DocumentTypes) => documentsOthers.find((doc) => doc.type === type),
    [documentsOthers]
  );

  const getIndex = useCallback(
    (type: DocumentTypes) => documents.findIndex((doc) => doc.type === type),
    [documents]
  );

  const indexContract = useMemo(
    () => getIndex(DocumentTypesEnum.contract),
    [getIndex]
  );
  const indexIncome = useMemo(
    () => getIndex(DocumentTypesEnum["sustainer-income-one"]),
    [getIndex]
  );
  const indexLEM = useMemo(() => getIndex(DocumentTypesEnum.lem), [getIndex]);
  const indexNEM = useMemo(() => getIndex(DocumentTypesEnum.nem), [getIndex]);

  const getStatus = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getSignDocumentation();
    if (data?.documents) {
      setDocuments(data.documents);
      setIdentifications(data.identityCards);
      setDocumentsOthers(data.documentsOthers);
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading(t("common.displayError.errorUnexpected"));
    }
    setLoading(false);
  }, [setLoading, setErrorLoading, t]);

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const onSubmit = async () => {
    if (
      documents.some((doc) => !doc.isSigned) ||
      identifications.some((identification) => !identification.isUploaded)
    ) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      const { error } = await verifySignDocumentation();
      if (!error) {
        nextStep();
      } else if (error && error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      } else {
        addToast({
          icon: "error",
          color: "danger",
          text: t(`${prefix}.nextStepError`),
        });
      }
    }
  };

  const onUploadDoc = (indexDoc: number) => {
    setDocuments((prevDocs) => {
      let newDocs = [...prevDocs];
      if (newDocs[indexDoc]) {
        newDocs[indexDoc].isSigned = true;
      }
      return newDocs;
    });
  };

  const onUploadDocOtherType = (documentType: DocumentTypes) => {
    setDocumentsOthers((prevDocs) => {
      const prevDoc = prevDocs.find((d) => d.type === documentType);
      if (prevDoc) {
        prevDoc.isSigned = true;
      }
      return [...prevDocs];
    });
  };

  const onUploadIdentification = (indexId: number) => {
    setIdentifications((prevIds) => {
      let ids = [...prevIds];
      ids[indexId].isUploaded = true;
      return ids;
    });
  };

  const getErrorTextDoc = useCallback(
    (indexDoc: number) => {
      return showErrors && !documents[indexDoc].isSigned
        ? requiredMsgError
        : undefined;
    },
    [showErrors, documents, requiredMsgError]
  );

  const downloadContract = useCallback(async () => {
    const { data, error } = await tokenSignPreviewDocument();
    if (data && !error) {
      const url = getPreviewContract(data);
      window.open(url);
    } else if (error) {
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
      addToast({
        icon: "error",
        color: "danger",
        text: t(`common.errors.download`),
      });
    }
  }, [setIsSessionExpired, t]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={getStatus}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col xs={12}>
            <StepBackButton />
          </Col>
          <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
            <HeaderStudentFullName prefix={prefix} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
          <Col xs={12} className="pb-2">
            <p className="fs-18 text-light fw-300">
              {t(`${prefix}.description`)}
            </p>
          </Col>
        </Row>

        {/* Listado de documentos a adjuntar */}
        <Row className="mx-n2 align-items-end">
          {indexContract > -1 && (
            <Col xs={12} md={6} lg={4} className="mb-3 px-2">
              <DocumentCard
                size="md"
                docName={"contract"}
                isUploaded={documents[indexContract].isSigned}
                errorText={getErrorTextDoc(indexContract)}
                onDownload={downloadContract}
                onUploaded={() => onUploadDoc(indexContract)}
              />
            </Col>
          )}
          {indexLEM > -1 && (
            <Col xs={12} md={6} lg={2} className="mb-3 px-2">
              <DocumentCard
                docName={"lem"}
                isUploaded={documents[indexLEM].isSigned}
                errorText={getErrorTextDoc(indexLEM)}
                onUploaded={() => onUploadDoc(indexLEM)}
              />
            </Col>
          )}
          {indexNEM > -1 && (
            <Col xs={12} md={6} lg={2} className="mb-3 px-2">
              <DocumentCard
                docName={"nem"}
                isUploaded={documents[indexNEM].isSigned}
                errorText={getErrorTextDoc(indexNEM)}
                onUploaded={() => onUploadDoc(indexNEM)}
              />
            </Col>
          )}

          {/* ADJUNTAR DOCUMENTACIÓN DE CARNET Ó PASAPORTE */}
          {identifications.map((identification, key) => {
            return (
              <Col
                xs={12}
                md={6}
                lg={2}
                className="mb-3 px-2"
                key={`identification_${key}`}
              >
                <DocumentCard
                  docName={identification.type}
                  isUploaded={identification.isUploaded}
                  errorText={
                    showErrors && !identification.isUploaded
                      ? requiredMsgError
                      : undefined
                  }
                  onUploaded={() => onUploadIdentification(key)}
                />
              </Col>
            );
          })}
          {indexIncome > -1 && (
            <>
              <Col xs={12} md={6} lg={2} className="mb-3 px-2">
                <DocumentCard
                  docName={"sustainer-income-one"}
                  isUploaded={documents[indexIncome].isSigned}
                  errorText={getErrorTextDoc(indexIncome)}
                  onUploaded={() => onUploadDoc(indexIncome)}
                />
              </Col>
              <Col xs={12} md={6} lg={2} className="mb-3 px-2">
                <DocumentCard
                  docName={"sustainer-income-others"}
                  isUploaded={
                    findDocumentOthers(
                      DocumentTypesEnum["sustainer-income-others"]
                    )?.isSigned ?? false
                  }
                  onUploaded={() =>
                    onUploadDocOtherType(
                      DocumentTypesEnum["sustainer-income-others"]
                    )
                  }
                />
              </Col>
            </>
          )}
        </Row>
        <Row className="pt-3 pb-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              text={t(`${prefix}.btnNext`)}
              onClick={onSubmit}
              fullwidth
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DocumentationNoSua;
