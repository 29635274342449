import { useState } from "react";
import {
  addToast,
  Modal,
  Icon,
  Button,
  SingleCardContainer,
} from "@octano/global-ui";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import { clean } from "rut.js";
import { requestSavePostulant } from "../../api/requests/tuitionProcess";
import { IS_SUA } from "../../config/constants";
import { useUserState } from "../../hooks/useUserState";
import { SavePostulantBody } from "../../types/postulantTypes";
import { setJWT } from "../../utils/auth";
import { formatDate } from "../../utils/dates";
import RegistrationForm, { RegistrationInputs } from "./parts/RegistrationForm";

import { ReactComponent as AddemicProductLogo } from "../../assets/addemic/logo_expanded.svg";
import { ReactComponent as CampusProductLogo } from "../../assets/campus/logo_expanded.svg";

import { IS_ADDEMIC } from "../../config/constants";

const RegistrationNoSua = () => {
  const prefix = "registration";
  const { t } = useTranslation();
  const methods = useForm<RegistrationInputs>();
  const { setIsLogged } = useUserState();
  const hassPassport = methods.watch("hasPassport");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const history = useHistory();

  const formatBodyData = (values: RegistrationInputs): SavePostulantBody => {
    return {
      type: values.hasPassport ? "passport" : "rut",
      names: values.names,
      rut: values.rut ? clean(values.rut) : undefined,
      passportNumber: values?.passportNumber,
      passportCountry: values?.passportCountry?.value
        ? values.passportCountry.value.toString()
        : undefined,
      birthday: formatDate(values.birthday, "YYYY-MM-DD"),
      paternalLastName: values.paternalLastName,
      maternalLastName: values.maternalLastName,
      paternalPreference: true,
      email: values.email,
      phone: values.phone,
    };
  };

  const onSubmit = async (values: RegistrationInputs) => {
    const params: SavePostulantBody = formatBodyData(values);
    const { data, error } = await requestSavePostulant(params);
    if (data) {
      addToast({
        icon: "success",
        color: "success",
        text: t(`${prefix}.saveSuccess`),
      });
      setJWT(data.accessToken);
      setIsLogged(true);
    }
    if (!error) return;

    if (error.data.message[0] === "USER_ALREADY_EXIST") {
      return addToast({
        icon: "error",
        color: "danger",
        text: t(`${prefix}.errors.USER_ALREADY_EXIST`, {
          accessType: hassPassport
            ? t(`${prefix}.form.passportNumber`)
            : t(`${prefix}.form.rut`),
        }),
      });
    }

    if (error.data.message === "not active postulation") {
      return setIsModalOpen(true);
    }

    addToast({
      icon: "error",
      color: "danger",
      text: t(`${prefix}.errors.DEFAULT`),
    });
  };

  const backToLogin = () => {
    setIsModalOpen(false);
    history.push("/auth/tuition-process");
  };

  // Solo se permite acceder a esta pantalla para el proceso SIN SUA
  // De lo contrario redirecciona al login
  if (IS_SUA) {
    return <Redirect to="/auth" />;
  }
  return (
    <>
      <SingleCardContainer
        brandLogoColor={
          IS_ADDEMIC ? <AddemicProductLogo /> : <CampusProductLogo />
        }
      >
        <div className="d-flex justify-content-center">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 80 }} />
          ) : (
            <img
              src="/tenant/logo_expanded.svg"
              alt="tenant_logo"
              style={{ maxHeight: 100 }}
            />
          )}
        </div>

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <RegistrationForm />
          </Form>
        </FormProvider>
        <Row>
          <Col xs={12} className="pt-4 text-center">
            <Link to="/auth" className="text-link">
              {t(`${prefix}.goBackToLogin`)}
            </Link>
          </Col>
        </Row>
      </SingleCardContainer>
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        closeOnBackdrop={false}
      >
        <div className="text-center">
          <Icon className="mb-2" name="warning" size="52px" color="secondary" />
          <p className="pt-4 text-dark fw-400 fs-22">
            {t(`${prefix}.errors.not_active_postulation_title`)}
          </p>
          <p className="fs-18 fw-300">
            {t(`${prefix}.errors.not_active_postulation_body`)}
          </p>
          <Button
            className="mt-4"
            text={t(`${prefix}.errors.closeModalBtn`)}
            onClick={backToLogin}
            fullwidth
          />
        </div>
      </Modal>
    </>
  );
};

export default RegistrationNoSua;
