import { useCallback, useContext } from 'react';
import {
  getCountriesRequest,
  getIntitutionTypesRequest,
  getRegionCommunesRequest,
} from '../api/requests/parameters';
import { ParametersContext } from '../providers/ParametersProvider';
import { ParametersActionType } from '../types/parametersTypes';
import { formatCountryOptions } from '../utils/selectFormat';

/**
 * Provee el estado y el carga de los parametros (opciones de selects)
 * utilizados en los formularios
 */
export const useParameters = () => {
  const { state, dispatch } = useContext(ParametersContext);

  const loadCountries = useCallback(async () => {
    const { data } = await getCountriesRequest();
    if (data) {
      const nationalities = data.map((row) => {
        return { value: row.id, label: row.nationality };
      });
      dispatch({
        type: ParametersActionType.SET_COUNTRIES,
        payload: formatCountryOptions(data),
      });
      dispatch({
        type: ParametersActionType.SET_NATIONALITIES,
        payload: nationalities,
      });
    }
  }, [dispatch]);

  const loadRegionsAndCommunes = useCallback(async () => {
    const { data } = await getRegionCommunesRequest();
    if (data) {
      const regions = data.regions.map((row) => {
        return { value: row.id, label: row.name };
      });
      const communes = data.communes.map((row) => {
        return { value: row.id, label: row.name, regionId: row.regionId };
      });
      dispatch({
        type: ParametersActionType.SET_REGIONS,
        payload: regions,
      });
      dispatch({
        type: ParametersActionType.SET_COMMUNES,
        payload: communes,
      });
    }
  }, [dispatch]);

  const loadInstitutionTypes = useCallback(async () => {
    const { data } = await getIntitutionTypesRequest();
    if (data) {
      const institutionTypes = data.data.map((row) => {
        return { value: row.id, label: row.name };
      });
      dispatch({
        type: ParametersActionType.SET_INSTITUTION_TYPES,
        payload: institutionTypes,
      });
    }
  }, [dispatch]);

  return {
    ...state,
    loadCountries,
    loadRegionsAndCommunes,
    loadInstitutionTypes,
  };
};
