import { AxiosError, Method } from "axios";
import { getJWT } from "../utils/auth";
import isAxiosError from "../utils/isAxiosError";
import { logger } from "../utils/logger";
import API from "./api";

export type RequestOptionsType = {
  method?: Method;
  data?: any;
  formData?: any;
  authRequired?: boolean;
  retry?: boolean;
};

const getHeaders = (authRequired: boolean) => {
  const headers: any = {};
  if (authRequired) {
    const token = getJWT();
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

type RequireOne<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AxiosErrorWithResponse<T = any> = RequireOne<
  AxiosError<T>,
  "response"
>;

type AxiosResultErrorHttp<T = any> = {
  code: "HTTP_ERROR";
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorConnection = {
  code: "CONNECTION";
  error: AxiosError;
  data?: null;
};

export type AxiosResultDefaultError =
  | AxiosResultErrorHttp
  | AxiosResultErrorConnection;

export type AxiosResult<
  T,
  E extends { code: string; data?: any } = AxiosResultDefaultError
> = { data: T; error?: false } | { data?: null; error: E };

const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {}
): Promise<AxiosResult<T>> => {
  const { method = "get", data = {}, authRequired = true } = options;
  try {
    const response = await API.request<T>({
      url: url,
      method: method,
      data: data,
      headers: getHeaders(authRequired),
    });
    return { data: response.data, error: false };
  } catch (error: unknown) {
    logger(error);
    if (isAxiosError(error) && error.response) {
      return {
        error: {
          code: "HTTP_ERROR",
          status: error.response.status,
          data: error.response.data,
          error: error as AxiosErrorWithResponse,
        },
      };
    }
    return {
      error: {
        code: "CONNECTION",
        error: error as AxiosError,
      },
    };
  }
};

export default request;
