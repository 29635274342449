// Endpoints relacionados con el proceso de matrícula
const TUITION_PROCESS = {
  ACTIVE_TUITION_PROCESS: "/tuition-processes/active",
  LOGIN: "/login",
  POSTULANT_REGISTRATION: "/save-postulant",
  TUITION_PROCESS_STEPS: "/tuition-process-steps",
  STUDENT_FILE: "/tuition-process-steps/student-file",
  SUSTAINER: "/tuition-process-steps/sustainer",
  COSIGNER: "/tuition-process-steps/cosigner",
  TERMS_AND_CONDITIONS: "/tuition-process-steps/terms-and-conditions",
  TERMS_AND_CONDITIONS_PREVIEW_TOKEN:
    "/tuition-process-steps/terms-and-conditions/preview/token",
  TERMS_AND_CONDITIONS_PREVIEW:
    "/tuition-process-steps/terms-and-conditions/preview",
  PAYMENT: "/tuition-process-steps/payment",
  SIGN_DOCUMENTATION: "/tuition-process-steps/sign-documentation",
  SIGN_DOCUMENTATION_SIGN: "/tuition-process-steps/sign-documentation/sign",
  SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD:
    "/tuition-process-steps/sign-documentation/identity-card",
  SIGN_DOCUMENTATION_VERIFY: "/tuition-process-steps/sign-documentation",
  COMPLETED: "/tuition-process-steps/completed",
  SIGN_DOCUMENTATION_LEM_NEM:
    "/tuition-process-steps/sign-documentation/upload-documents",
  SIGN_DOCUMENTATION_PREVIEW_TOKEN:
    "/tuition-process-steps/sign-documentation/preview-document/token",
  SIGN_DOCUMENTATION_PREVIEW:
    "/tuition-process-steps/sign-documentation/preview-document",
  SIGN_DOWNLOAD_CONTRACT:
    "/tuition-process-steps/sign-documentation/download-contract",
  TARIFF_QUOTAS: "/tuition-process-steps/tariff/inf/",
  TARIFF_QUOTAS_SAVE: "/tuition-process-steps/tariff/save-quota/",
  SIGN_DOCUMENTATION_SUSTAINER_INCOME:
    "/tuition-process-steps/sign-documentation/upload-sustainer-income",
  SIGN_DOCUMENTATION_SUSTAINER_INCOME_OTHERS:
    "/tuition-process-steps/sign-documentation/upload-other-document",
};

const POSTULATION = {
  INFORMATION_WAITING_LIST: "/postulation/information-waiting-list",
  CREATE: "/postulation/create",
};

const STUDY_PLANS = {
  STUDY_PLAN_QUOTA: "/study-plans/study-plan-quota",
};

const PARAMETERS = {
  COUNTRIES: "/countries",
  INSTITUTION_TYPES: "/postulation/institutions-types",
  REGIONS_COMMUNES: "/region/all",
};

const CERTIFICATES = {
  FIND_ONE: "/certificates-validations",
};

const SETTINGS = {
  TIMEZONE: "/settings/timezone",
};

export {
  TUITION_PROCESS,
  STUDY_PLANS,
  PARAMETERS,
  POSTULATION,
  CERTIFICATES,
  SETTINGS,
};
