import { addToast, Button, SelectOptionType } from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useForm, FormProvider, DefaultValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { clean } from 'rut.js';
import { AxiosResultDefaultError } from '../../../../api/request';
import { saveSustainer } from '../../../../api/requests/tuitionProcess';
import ContactSection from '../../../../components/form/ContactSection';
import { useStepState } from '../../../../hooks/useStepState';
import { useUserState } from '../../../../hooks/useUserState';
import { DataOwnSustainer } from '../../../../types/sustainerTypes';
import PersonalInformation from './PersonalInformation';

export type FieldsSustainerForm = {
  relationship: SelectOptionType | null;
  occupation: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry: SelectOptionType | null;
  nationality: SelectOptionType | null;
  maritalStatus: SelectOptionType | null;
  ownSustainer: boolean;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
  phone: string;
  cellphone: string;
  email: string;
};

type SustainerFormProps = {
  sustainerPassportAvailable: boolean;
  defaultValues?: DefaultValues<FieldsSustainerForm>;
  dataOwnSustainer?: DataOwnSustainer;
};

const SustainerForm = ({
  sustainerPassportAvailable,
  defaultValues,
  dataOwnSustainer,
}: SustainerFormProps) => {
  const prefix = 'tuitionProcess.sustainer';
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const methods = useForm<FieldsSustainerForm>({
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;
  const { nextStep } = useStepState();
  const ownSustainer = watch('ownSustainer');
  const postulantHasPassport = Boolean(dataOwnSustainer?.passportNumber);

  const setOwnSustainerPersonalInformation = useCallback(() => {
    setValue('relationship', dataOwnSustainer?.relationship ?? null);
    setValue('rut', dataOwnSustainer?.rut ?? '');
    setValue('passportCountry', dataOwnSustainer?.passportCountry ?? null);
    setValue('passportNumber', dataOwnSustainer?.passportNumber ?? '');
    setValue('names', dataOwnSustainer?.names ?? '');
    setValue('paternalLastName', dataOwnSustainer?.paternalLastName ?? '');
    setValue('maternalLastName', dataOwnSustainer?.maternalLastName ?? '');
    setValue('nationality', dataOwnSustainer?.nationality ?? null);
    setValue('maritalStatus', dataOwnSustainer?.maritalStatus ?? null);
  }, [dataOwnSustainer, setValue]);

  const setOwnSustainerContact = useCallback(() => {
    setValue('addressStreet', dataOwnSustainer?.addressStreet ?? '');
    setValue('addressNumber', dataOwnSustainer?.addressNumber ?? '');
    setValue('addressExtra', dataOwnSustainer?.addressExtra ?? '');
    setValue('region', dataOwnSustainer?.region ?? null);
    setValue('commune', dataOwnSustainer?.commune ?? null);
    setValue('phone', dataOwnSustainer?.phone ?? '');
    setValue('cellphone', dataOwnSustainer?.cellphone ?? '');
    setValue('email', dataOwnSustainer?.email ?? '');
  }, [dataOwnSustainer, setValue]);

  const onChangeOwnSustainer = useCallback(() => {
    if (ownSustainer) {
      setOwnSustainerPersonalInformation();
      setOwnSustainerContact();
    } else {
      setValue('relationship', null);
      setValue('occupation', '');
      setValue('rut', '');
      setValue('names', '');
      setValue('paternalLastName', '');
      setValue('maternalLastName', '');
      setValue('addressStreet', '');
      setValue('addressNumber', '');
      setValue('addressExtra', '');
      setValue('region', null);
      setValue('commune', null);
      setValue('phone', '');
      setValue('cellphone', '');
      setValue('email', '');
      setValue('nationality', null);
      setValue('maritalStatus', null);
    }
  }, [
    ownSustainer,
    setValue,
    setOwnSustainerPersonalInformation,
    setOwnSustainerContact,
  ]);

  useEffect(() => {
    if (ownSustainer !== undefined) {
      onChangeOwnSustainer();
    }
  }, [ownSustainer, onChangeOwnSustainer]);

  const showSubmitState = useCallback(
    (error: false | AxiosResultDefaultError | undefined) => {
      if (error) {
        if (error.code === 'HTTP_ERROR' && error.status === 401) {
          setIsSessionExpired(true);
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.saveError`),
          });
        }
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      }
    },
    [t, nextStep, setIsSessionExpired],
  );

  const onSubmit = useCallback(
    async (values: FieldsSustainerForm) => {
      let saveValues = values;
      if (values.ownSustainer) {
        // Para guardar los valores del alumno como su propio sustantador,
        // se mezcla los values con los datos de dataOwnSustainer
        // ya que al dejar los inputs disabled react hook forms retorna los valores como undefined
        saveValues = { ...values, ...dataOwnSustainer };
      }
      if (
        !(
          saveValues.commune &&
          saveValues.relationship &&
          saveValues.nationality &&
          saveValues.maritalStatus
        )
      ) {
        return;
      }
      const { error } = await saveSustainer({
        type: saveValues.rut ? 'rut' : 'passport',
        rut: saveValues.rut ? clean(saveValues.rut) : undefined,
        passportCountry:
          saveValues?.passportCountry?.value.toString() ?? undefined,
        passportNumber: saveValues?.passportNumber,
        names: saveValues.names,
        paternalLastName: saveValues.paternalLastName,
        maternalLastName: saveValues.maternalLastName,
        relationshipId: +saveValues.relationship?.value,
        occupation: saveValues.occupation,
        addressStreet: saveValues.addressStreet,
        addressNumber: saveValues.addressNumber,
        addressExtra: saveValues.addressExtra ?? null,
        communeId: +saveValues.commune?.value,
        phone: saveValues.phone ?? null,
        cellPhone: saveValues.cellphone,
        email: saveValues.email,
        nationalityId: saveValues.nationality.value.toString(),
        maritalStatusId: +saveValues.maritalStatus?.value,
      });

      showSubmitState(error);
    },
    [dataOwnSustainer, showSubmitState],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          sustainerPassportAvailable={sustainerPassportAvailable}
          postulantHasPassport={postulantHasPassport}
        />
        <ContactSection
          prefix={`${prefix}.contact`}
          disabled={ownSustainer === true}
        />
        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default SustainerForm;
